import { Calendar, EventProps, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useClickAway } from 'react-use';
import './BigCalendar.styles.css';
import classnames from 'classnames';
import { ChevronRightIcon, ChevronLeftIcon, InformationCircleIcon } from '@heroicons/react/solid';
import Button from 'components/common/Button';
import { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AppointmentAPI from 'api/AppointmentAPI';
import PopoverTask from 'components/PopoverTask';
import LoadingOverlay from 'react-loading-overlay-ts';
import MultiSelect from 'components/common/MultiSelect';
import { groupBy } from 'lodash';
import LookUpAPI from 'api/LookUpsAPI';
import { toast } from 'react-toastify';
import CalendarHint from './CalendarHint';
import GlobalCalendar from '../GlobalCalendar';
import { RootStateOrAny, useSelector } from 'react-redux';

const localizer = momentLocalizer(moment);
const timeZone = moment.tz.guess();

const styles = {
  borderRadius: '18px',
  border: 'none',
};

const BigCalendar: React.FC = () => {
  const calendarClasses = classnames('m-6 bg-white h-screen');
  const [doctorsList, setDoctorsList] = useState([]);
  const [activeFilter, setActiveFilter] = useState<{ label: string; value: string }[]>([]);
  const [globalEventsList, setGlobalEventsList] = useState([]);
  const [currentTab, setCurrentTab] = useState('ownTasks');
  const [showBigTemplates, setShowBigTemplates] = useState(false);
  const [events, setEvents] = useState([] as any);
  const [showPopover, setShowPopover] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState('');
  const [intialDate, setIntialDate] = useState<any>();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const userInfo = useSelector((state: RootStateOrAny) => state.userProfile.userDetails);

  const onClickLinkToPreConsult = (preConsultTaskId: string) => {
    setSelectedAppointment(preConsultTaskId);
  };

  useEffect(() => {
    LookUpAPI.fetchUsers({ userType: 'Physician' })
      .then((res) => {
        if (res.status !== 200 || !Array.isArray(res.data.data)) {
          throw new Error("Can't get Physicians");
        }
        const arr = res.data.data.map((doctor: { name: string; _id: string }) => ({
          label: doctor.name,
          value: doctor._id,
        }));
        setDoctorsList(arr);
      })
      .catch((e) => {
        console.warn(e);
      });
  }, []);

  useEffect(() => {
    const curr = new Date();
    const initialDate = fetchCurrentWeekDates(curr);
    if (currentTab === 'ownTasks') {
      fetchEventsData(initialDate);
    } else {
      getGlobalEvents(initialDate);
    }
  }, [currentTab]);

  useEffect(() => {
    const initialDate = fetchCurrentWeekDates(intialDate);
    getGlobalEvents(initialDate);
  }, [activeFilter]);

  const fetchCurrentWeekDates = (curr: any) => {
    const firstday = moment(curr).startOf('week').toDate();
    const lastday = moment(curr).endOf('week').toDate();
    const data = {
      startDate: moment(firstday).format('YYYY-MM-DD'),
      endDate: moment(lastday).format('YYYY-MM-DD'),
      timezone: timeZone,
    };

    return data;
  };

  const fetchEventsData = (data: any) => {
    setLoading(true);
    setIntialDate(data.startDate);
    AppointmentAPI.fetchAppointments(data)
      .then((res) => {
        if (res.data.data) {
          const eventData: any = [];
          setLoading(false);

          res.data.data.map((eachAppointment: any) => {
            eventData.push({
              status: eachAppointment.appointmentStatus,
              title: eachAppointment.patientData[0].name,
              id: eachAppointment._id,
              taskId:
                eachAppointment.appointmentStatus === 'completed'
                  ? eachAppointment.postConsultTaskId
                  : eachAppointment.taskId,
              allDay: false,
              start: new Date(eachAppointment.appointmentTime.startTime),
              end: new Date(eachAppointment.appointmentTime.endTime),
              isMine: false,
              passedConsult: new Date() > new Date(eachAppointment.appointmentTime.endTime),
              isActionRequired: eachAppointment.isActionRequired,
            });
          });

          setEvents(eventData);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const CustomToolbar = (toolbar: any) => {
    const goToBack = () => {
      const mDate = toolbar.date;
      let newDate: any = '';
      newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() - 7, 1);
      toolbar.onNavigate('prev', newDate);
      const prevData = fetchCurrentWeekDates(newDate);
      if (currentTab === 'ownTasks') {
        fetchEventsData(prevData);
      } else {
        getGlobalEvents(prevData);
      }
    };

    const goToNext = () => {
      const mDate = toolbar.date;
      let newDate: any = '';
      newDate = new Date(mDate.getFullYear(), mDate.getMonth(), mDate.getDate() + 7, 1);
      toolbar.onNavigate('next', newDate);
      const nextData = fetchCurrentWeekDates(newDate);
      if (currentTab === 'ownTasks') {
        fetchEventsData(nextData);
      } else {
        getGlobalEvents(nextData);
      }
    };

    const goToToday = () => {
      const now = new Date();
      let newDate: any = '';
      newDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 1);
      toolbar.onNavigate('current', newDate);
      const todayData = fetchCurrentWeekDates(now);
      if (currentTab === 'ownTasks') {
        fetchEventsData(todayData);
      } else {
        getGlobalEvents(todayData);
      }
    };

    return (
      <div className="flex items-center pl-9 pt-8 mb-6 rounded-lg">
        <div className="text-text text-2xl mr-4">{moment(intialDate).format('MMMM YYYY')}</div>
        <div className="flex justify-between mr-4">
          <ChevronLeftIcon className="w-5 h-5 cursor-pointer" onClick={() => goToBack()} />
          <ChevronRightIcon className="w-5 h-5 cursor-pointer" onClick={() => goToNext()} />
        </div>
        <div className="underline font-bold mr-4 text-2xl text-text cursor-pointer" onClick={() => goToToday()}>
          Today
        </div>
        <div className="flex items-center justify-start group relative cursor-pointer">
          <InformationCircleIcon className="w-5 h-5 mr-3 text-helpIconBackground cursor-pointer" />
          <span className="text-lg">key</span>
          <CalendarHint />
        </div>
      </div>
    );
  };

  const handleSelection = (e: { label: string; value: string }[]) => {
    setActiveFilter(e);
  };

  const onclickEventHandler = (value: any) => {
    setSelectedAppointment(value.taskId);
    setShowPopover(true);
  };
  const popoverCancelClickHandler = () => {
    setShowPopover(false);
    setSelectedAppointment('');
  };

  const onCanelComplete = () => {
    history.push('/dashboard/appointments');
    popoverCancelClickHandler();
  };

  const onSuccessCancel = () => {
    const dates = fetchCurrentWeekDates(intialDate);
    fetchEventsData(dates);
  };

  async function getGlobalEvents(period: { startDate: string; endDate: string }) {
    try {
      setLoading(true);
      setIntialDate(period.startDate);
      const doctorIDs = activeFilter.map((f) => f.value);
      const res = await AppointmentAPI.fetchGlobalAppointments({ ...period, timezone: timeZone, doctorIDs });
      if (res.status !== 200) {
        throw new Error('Error while getting global events');
      }
      const glEvArr = res.data.data.map((ev: { time: string; appointments: any[] }) => {
        return {
          start: moment(ev.time).toDate(),
          end: moment(ev.time).add(58, 'm').toDate(),
          appointments: ev.appointments,
        };
      });
      setGlobalEventsList(glEvArr);
    } catch (error) {
      console.warn(error);
      toast.error('Can`t fetch appointments');
    } finally {
      setLoading(false);
    }
  }

  const parseStyles = (event: { appointments: []; start: string; end: string }) => {
    if (event.appointments?.length > 3) {
      return {
        style: {
          ...styles,
          backgroundColor: '#64CCC9',
          borderRadius: '4px',
        },
      };
    }
    if (event.appointments?.length > 1) {
      return {
        style: {
          ...styles,
          backgroundColor: '#64CCC95E',
          borderRadius: '4px',
        },
      };
    }
    return {
      style: {
        ...styles,
        backgroundColor: '#64CCC926',
        borderRadius: '4px',
      },
    };
  };

  return (
    <>
      <div className="flex">
        <div className="mx-10 w-full h-screen">
          <div className="cal-availability py-1.5">
            <h2 className="text-xl ml-6 mb-4 font-semibold">Calendar</h2>
            {userInfo.userType?.name === 'Physician' && (
              <Link to={`./appointment-availablity/${userInfo._id}`}>
                <Button type="success" ownClasses="px-8 py-1.5 mr-4 text-lg rounded-full">
                  Edit availability
                </Button>
              </Link>
            )}
          </div>
          <div className="flex mx-6">
            <Button
              onClick={() => setCurrentTab('ownTasks')}
              type={currentTab === 'ownTasks' ? 'success' : 'secondary'}
              ownClasses="px-8 py-1.5 mr-4 2xl:text-lg rounded-full"
            >
              <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
              My tasks
            </Button>
            <Button
              onClick={() => setCurrentTab('globalTasks')}
              type={currentTab === 'globalTasks' ? 'success' : 'secondary'}
              ownClasses="px-8 py-1.5 mr-4 2xl:text-lg rounded-full"
            >
              <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
              Global calendar
            </Button>
            {(userInfo.userType?.shortCode === 'MA' || userInfo.userType?.shortCode === 'AD') && (
              <Button
                onClick={() => setCurrentTab('availability')}
                type={currentTab === 'availability' ? 'success' : 'secondary'}
                ownClasses="px-8 py-1.5 mr-4 2xl:text-lg rounded-full"
              >
                <i className="w-2.5 h-2.5 mr-2 bg-white rounded-full" />
                Availability
              </Button>
            )}
          </div>

          {showPopover && selectedAppointment ? (
            <>
              <PopoverTask
                onCancel={popoverCancelClickHandler}
                onClickLinkToPreConsult={onClickLinkToPreConsult}
                taskId={selectedAppointment && selectedAppointment}
                onCanelComplete={onCanelComplete}
                onSuccessCancel={onSuccessCancel}
              />
            </>
          ) : null}
          <LoadingOverlay active={loading} spinner>
            <div className={calendarClasses}>
              {currentTab === 'ownTasks' && (
                <Calendar
                  scrollToTime={new Date()}
                  localizer={localizer}
                  events={events && events}
                  onSelectEvent={onclickEventHandler}
                  defaultDate={new Date()}
                  eventPropGetter={(event) => {
                    if (['cancelled', 'missed'].includes(event.status)) {
                      return {
                        style: {
                          ...styles,
                          backgroundColor: '#FFF2F5',
                        },
                      };
                    }
                    if (event.passedConsult) {
                      return {
                        style: { ...styles, backgroundColor: '#E0F4F4' },
                      };
                    }
                    return {
                      style: {
                        ...styles,
                        backgroundColor: '#64CCC9',
                      },
                    };
                  }}
                  defaultView="week"
                  components={{
                    toolbar: CustomToolbar,
                    event: CustomEvent,
                  }}
                  views={['week']}
                  endAccessor={({ end }) => end}
                />
              )}
              {currentTab === 'globalTasks' && (
                // Global Calendar
                <>
                  <div className="p-4">
                    <h2 className="text sm font-bold mb-3">Filter by Physicians</h2>
                    <div className="w-1/3">
                      <MultiSelect
                        handleChange={handleSelection}
                        value={activeFilter}
                        placeholder="All"
                        options={doctorsList}
                      />
                    </div>
                  </div>
                  <Calendar
                    scrollToTime={new Date()}
                    localizer={localizer}
                    events={globalEventsList}
                    defaultDate={new Date()}
                    eventPropGetter={parseStyles}
                    defaultView="week"
                    components={{
                      toolbar: CustomToolbar,
                      event: function Event(props) {
                        return <CustomGlobalEvent {...props} openPopup={onclickEventHandler} />;
                      },
                    }}
                    views={['week']}
                  />
                </>
              )}
              {currentTab === 'availability' && <GlobalCalendar />}
            </div>
          </LoadingOverlay>
        </div>
      </div>
    </>
  );
};

const CustomEvent:
  | React.ComponentType<
      EventProps<{
        status: string;
        title: string;
        allDay: boolean;
        start: Date;
        end: Date;
        isMine: boolean;
        passedConsult: boolean;
        id: string;
        taskId: string;
        isActionRequired: boolean;
      }>
    >
  | undefined = ({ event }) => {
  return (
    <>
      <span
        className={`text-sm overflow-hidden overflow-ellipsis text-${
          ['missed', 'cancelled'].includes(event.status) ? 'red' : event.passedConsult ? 'darkBlue' : 'white'
        }`}
      >
        {event.title}
      </span>
      {event.isActionRequired && (
        <div className="w-3 h-3 mt-px ml-auto bg-red border-2 border-white rounded-full "></div>
      )}
    </>
  );
};

const CustomGlobalEvent: React.ComponentType<
  EventProps<{
    appointments: [];
    start: string;
    end: string;
  }> & { openPopup: (value: any) => void }
> = ({ event, openPopup }) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  useClickAway(popupRef, () => {
    setShowPopup(false);
  });
  const groupedAppointments = groupBy(event.appointments, 'staffName');
  const sortedAppts = [];
  for (const doctor in groupedAppointments) {
    const doctorAppts = groupedAppointments[doctor];
    sortedAppts.push({
      doctor,
      list: doctorAppts.map((appt: any) => ({
        time: moment(appt.appointmentTime?.startTime).format('h:mm a'),
        patient: appt.patientData[0]?.name,
        taskId: appt.appointmentStatus === 'completed' ? appt.postConsultTaskId : appt.taskId,
      })),
    });
  }

  const parseTextColor = (eventsAmount: string | number): string => {
    if (eventsAmount < 4) {
      return 'text-darkBlue';
    }
    return 'text-white';
  };
  const handleSingleApptClick = (event: React.MouseEvent, taskId: string) => {
    event.stopPropagation();
    openPopup({ taskId });
  };

  const setPopupAlignment = () => {
    const weekDay = (event.start as unknown as Date).getDay();
    // 5 and 6 are numbers for weekdays friday/saturday
    if ([5, 6].includes(weekDay)) {
      return 'absolute w-60 shadow-full text-black rounded-xl bg-white -left-0 -top-24 z-30 py-3 px-4';
    }
    return 'absolute w-60 shadow-full text-black rounded-xl bg-white left-full -top-14 z-30 py-3 px-4';
  };

  return (
    <div onClick={() => setShowPopup(!showPopup)} ref={popupRef} className="relative w-full h-full flex">
      <div className="overflow-hidden m-auto">
        <span
          className={classnames(
            'flex text-sm justify-between w-full font-bold',
            parseTextColor(event.appointments.length),
          )}
        >{`${event.appointments?.length} ${event.appointments?.length > 1 ? 'appts' : 'appt'}`}</span>
      </div>

      {showPopup && (
        <div className={setPopupAlignment()}>
          {sortedAppts.map((appt) => {
            return (
              <div key={appt.doctor}>
                <div className="flex justify-between">
                  <span className="font-semibold text-sm text-darkBlue mb-2">{appt.doctor}</span>
                  <span className="text-textGreyedOut text-sm">{appt.list.length} appt (s)</span>
                </div>
                {appt.list.map((a) => (
                  <div
                    onClick={(e) => handleSingleApptClick(e, a.taskId)}
                    key={a.time}
                    className="flex my-3 items-center"
                  >
                    <span className="w-16 mr-3 text-textGreyedOut text-xs">{a.time}</span>
                    <span className="underline text-xs w-full truncate">{a.patient}</span>
                  </div>
                ))}
                <div className="h-px bg-lightBorder my-2"></div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BigCalendar;
